input {
  margin: 0;
}

span {
  display: inline-block;
  width: 11px;
  height: 11px;
}

.hovered {
  background-color: red;
}
